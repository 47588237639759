<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <Table
      rowKey="id"
      :isLoading="$store.getters['schedules/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['schedules/get'](params)"
    >
      <template #top>
        <div class="p-16 border-bottom d-flex">
          <ScheduleFilter />
          <router-link
            class="ms-auto"
            :to="`/admin/kelas/${$route.params.grade_id}/jadwal/tambah`"
            v-tooltip="'Tambah'"
            ><PlusCircleIcon
          /></router-link>
        </div>
      </template>

      <template #bottom>
        <div class="border-top p-16" v-if="meta && meta.last_page > 1">
          <Pagination :current="meta.current_page" :last="meta.last_page" />
        </div>
      </template>
    </Table>
  </main>
</template>

<script>
import merge from 'lodash/merge';
import scheduleRepository from '../../../../api/repositories/scheduleRepository';
import Grade from '../../../../store/models/Grade';

import ScheduleFilter from '../../../../components/admin/schedule/ScheduleFilter.vue';
import Table from '../../../../components/table/Table.vue';
import Pagination from '../../../../components/table/Pagination.vue';
import RowActions from '../../../../components/table/RowActions.vue';

import PencilIcon from 'bootstrap-icons/icons/pencil.svg';
import PlusCircleIcon from 'bootstrap-icons/icons/plus-circle.svg';
import TrashIcon from 'bootstrap-icons/icons/trash.svg';

export default {
  components: { ScheduleFilter, Table, Pagination, PlusCircleIcon },

  computed: {
    params() {
      return merge(
        {
          filter: { grade_id: this.$route.params.grade_id },
          sort: { day: 'asc', time_start: 'asc' },
        },
        this.$route.query
      );
    },

    grade() {
      return Grade.find(this.$route.params.grade_id);
    },

    columns() {
      return [
        { key: 'dayText', text: 'Hari' },
        { key: 'timeText', text: 'Jam' },
        { key: 'subject.name', text: 'Pelajaran' },
        { key: 'teacher.user.name', text: 'Guru' },
        {
          key: 'actions',
          component: RowActions,
          componentProps: {
            actions: () => [
              {
                icon: PencilIcon,
                text: 'Ubah',
                onClick: ({ row }) => this.$router.push(row.editRoutePath),
              },
              {
                icon: TrashIcon,
                text: 'Hapus',
                attrs: { class: 'text-danger' },
                onClick: this.handleRemoveClick,
              },
            ],
          },
          props: { class: 'text-end' },
        },
      ];
    },

    meta() {
      return this.$store.getters['schedules/getMeta'](this.params);
    },
  },

  metaInfo() {
    return { title: 'Jadwal' };
  },

  methods: {
    handleRemoveClick({ row }) {
      this.$confirmation.confirm(
        `Anda yakin mau menghapus jadwal <b>${row.subject.name}</b>?`,
        {
          onOK: async () => {
            try {
              await scheduleRepository.destroy(row.id);
              this.$store.dispatch('schedules/fetchAndInvalidate', this.params);
              this.$alert.success('Jadwal berhasil dihapus');
            } catch (error) {
              this.$alert.requestError(error);
            }
          },
        }
      );
    },

    fetch() {
      this.$store.dispatch('schedules/maybeFetch', this.params);
    },
  },

  async beforeMount() {
    this.fetch();

    if (!this.grade) {
      await this.$store.dispatch('grades/maybeFetch', {
        filter: { id: this.params.grade_id },
      });
    }

    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/kelas', label: 'Kelas' },
      { path: this.grade.editRoutePath, label: this.grade.name },
    ]);
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
};
</script>
