<template>
  <div class="wrapper d-flex align-items-center">
    <VDropdown class="d-inline-flex align-items-center me-16">
      <!-- This will be the popover reference (for the events and position) -->
      <button
        class="btn-plain d-inline-flex align-items-center"
        v-tooltip="'Cari'"
      >
        <SearchIcon />
      </button>

      <!-- This will be the content of the popover -->
      <template #popper>
        <div class="popper-content p-16">
          <SelectField
            label="Hari"
            :multiselect="{ options: options.day, placeholder: '' }"
            v-model="input.filter.day"
          />
          <SubjectSelectField
            class="mt-16"
            label="Pelajaran"
            v-model="input.filter.subject_id"
          />
          <TeacherSelectField
            class="mt-16"
            label="Guru"
            v-model="input.filter.teacher_id"
          />
        </div>
      </template>
    </VDropdown>

    <ActiveFilters :activeFilters="activeFilters" @remove="removeFilter" />
  </div>
</template>

<script>
import Schedule from '../../../store/models/Schedule';
import Subject from '../../../store/models/Subject';
import Teacher from '../../../store/models/Teacher';

import SelectField from '../../form/SelectField.vue';
import SubjectSelectField from '../../subject/SubjectSelectField.vue';
import ActiveFilters from '../../table/ActiveFilters.vue';
import BaseFilter from '../../table/BaseFilter.vue';
import TeacherSelectField from '../../teacher/TeacherSelectField.vue';
import VDropdown from '../../v-tooltip/VDropdown.vue';

import SearchIcon from 'bootstrap-icons/icons/search.svg';

export default {
  extends: BaseFilter,

  components: {
    SelectField,
    SubjectSelectField,
    ActiveFilters,
    TeacherSelectField,
    VDropdown,
    SearchIcon,
  },

  computed: {
    options() {
      return { day: Schedule.dayOptions };
    },
  },

  methods: {
    /**
     * @see BaseFilter.activeFilters
     */
    getActiveFilterDay(value) {
      const day = Schedule.DAY_TEXT[value];

      if (day) {
        return { label: 'Hari', key: 'day', value: day };
      }
    },

    /**
     * @see BaseFilter.activeFilters
     */
    getActiveFilterSubjectId(value) {
      const subject = Subject.find(value);

      if (subject) {
        return { label: 'Pelajaran', key: 'subject_id', value: subject.name };
      }
    },

    /**
     * @see BaseFilter.activeFilters
     */
    getActiveFilterTeacherId(value) {
      const teacher = Teacher.query().withAll().find(value);

      if (teacher) {
        return { label: 'Guru', key: 'teacher_id', value: teacher.user.name };
      }
    },
  },
};
</script>
