<template>
  <SelectField
    :multiselect="{
      loading: $store.getters['teachers/isFetching'](params),
      options,
      placeholder: '',
    }"
    :multiselectOn="{ 'search-change': (q) => (query = q || null) }"
    v-bind="$props"
    @input="$emit('input', $event)"
  />
</template>

<script>
import BaseField from '../form/BaseField.vue';
import SelectField from '../form/SelectField.vue';

export default {
  extends: BaseField,

  components: { SelectField },

  data() {
    return { query: null };
  },

  computed: {
    params() {
      const params = {};

      if (this.query) params.search = this.query;

      return params;
    },

    options() {
      return this.$store.getters['teachers/get'](this.params).map(
        (teacher) => ({
          value: teacher.id,
          label: teacher.user.name,
        })
      );
    },
  },

  watch: {
    params() {
      this.$store.dispatch('teachers/maybeFetch', this.params);
    },
  },

  beforeMount() {
    this.$store.dispatch('teachers/maybeFetch', this.params);
  },
};
</script>
